const term = (info: any) => {
  return `<ul style="list-style-type:disc">
        <li>Program POS Referral ini berlaku di bulan <strong>${info.period}</strong>.</li>
        <li>Agen Mitra POS yang berhasil mengajak teman untuk bergabung dan melakukan Top Up senilai ${info.topUp} maka akan mendapatkan insentif sebesar ${info.incentive}.</li>
        <li>Selain mendapatkan insentif Rp500.000 ketika sudah berhasil bergabung menjadi POS dan Top Up, Agen yang mereferralkan juga berhak mendapatkan insentif tambahan <b>${info.insentif}</b> dari Omset Referral.</li>
        <li>Perhitungan Insentif tambahan dari Omset <strong>${info.insentif}</strong> dihitung saat POS yang direferralkan melakukan  transaksi pertama kali dan selama 90 hari kedepan.</li>
        <li>Program insentif <b>${info.insentif}</b> ini berlaku di bulan <b>Oktober-Desember 2024</b> dan melakukan transaksi pertama kali maksimum di <b>Januari 2025</b> (H+1 Bulan  sejak periode program berakhir).</li>
        <li>Lion Parcel berhak tidak memberikan insentif <b>${info.insentif}</b> kepada pemberi referral jika ada POS pemberi referral dengan sengaja memindahkan paket ke referralnya dengan tujuan meningkatkan Omset.</li>
        <li>Insentif akan dibayarkan dalam jangka waktu H+1 bulan dalam bentuk Saldo POS (<b>Program Referral Rp.500.000</b>).</li>
        <li>Insentif akan dibayarkan dalam jangka waktu H+2 bulan dalam bentuk Saldo POS (<b>Program Referral 5% Omset</b>).</li>
        <li><b>Syarat untuk mendapatkan insentif yaitu: </b></li>
            <ul style="list-style-type: '-'; padding-left: 10px;">
                <li style="padding-left: 8px;"> Calon Agen Mitra POS wajib memilih Rekomendasi dari Agen POS Lain pada pertanyaan Darimana Anda mendapatkan informasi tentang pendaftaran ini.</li>
                <li style="padding-left: 8px;"> Mengisi Kode Referral  <b>“POS Code”</b>, contoh: <b>${info.code}</b> pada form perorangan / perusahaan di website Lion Parcel. </li>
                <li style="padding-left: 8px;"> Lokasi POS telah disetujui oleh tim Lion Parcel. </li>
            </ul>
        <li>Insentif akan dihitung setelah Agen Mitra POS yang diajak sudah disetujui oleh Tim Lion Parcel dan telah melakukan Top Up saldo awal sebesar ${info.topUp} dalam tempo waktu yang telah ditentukan.</li>
        <li>Tim NPOS akan melaporkan transaksi-transaksi yang terjadi kepada Tim Finance sesuai dengan aturan yang berlaku.</li>
        <li>Informasi lebih lanjut tentang program ini. Anda bisa menghubungi di 0821-2281-4040/0821-2281-4242.</li>
        <li>Bagikan Kode Referral Anda kepada Calon Agen Mitra POS dengan menyalin link perorangan: <a href="https://lionparcel.com/agen/perorangan-app?referral=${info.code}" target="_blank" style="color: red" class="underline">https://lionparcel.com/agen/perorangan-app?referral=${info.code}</a> atau menyalin link perusahaan: <a href="https://lionparcel.com/agen/perusahaan-app?referral=${info.code}" target="_blank" style="color: red" class="underline">https://lionparcel.com/agen/perusahaan-app?referral=${info.code}</a>. Silahkan daftarkan Calon Agen Mitra POS Anda dengan klik tombol dibawah ini:</li>
    </ul>`;
};

export default term;
