
import { Vue, Options } from "vue-class-component";
import { defineAsyncComponent } from "@vue/runtime-core";
import SectionFilterDashboard from "@/app/ui/views/dashboard/component/client/section-filter-dashboard.vue";
import Refresh from "@/app/ui/components/icons/modules/refresh.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
const SectionDeliveryDashboard = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/client/section-delivery/index.vue")
);
const SectionDeliveryPerformanceDashboard = defineAsyncComponent(() =>
  import(
    "@/app/ui/views/dashboard/component/client/section-delivery-performance/index.vue"
  )
);
@Options({
  components: {
    Refresh,
    SectionDeliveryPerformanceDashboard,
    SectionDeliveryDashboard,
    SectionFilterDashboard
  }
})
export default class PerformanceDashboard extends Vue {
  mounted() {
    this.getLastSyncDataDashboard();
  }
  checkActiveSectionDashboard(section: string) {
    const configPosPerformanceDashboard = FlagsMedusa.config_pos_performance_dashboard.getValue();
    if (!configPosPerformanceDashboard) return false;
    return configPosPerformanceDashboard.includes(section);
  }

  loadLastSyncCorporateDashboard = true;
  lastSyncCorporateDashboard = "";
  async getLastSyncDataDashboard() {
    this.loadLastSyncCorporateDashboard = true;
    const response = await DashboardController.getCorporateDashboardLastSyncData();
    this.lastSyncCorporateDashboard = response.lastUpdatedAt;
    this.loadLastSyncCorporateDashboard = false;
  }
  get formattedLastSyncDataDashboard() {
    if (!this.lastSyncCorporateDashboard) return "";
    return this.$moment(this.lastSyncCorporateDashboard).format(
      "DD MMMM YYYY, HH:mm"
    );
  }

  performanceDashboardKey = 0;
  reRenderDashboard() {
    this.performanceDashboardKey++;
  }
}
