/* eslint-disable @typescript-eslint/camelcase */
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { ProgressiveCommissionConfigurationRequest } from "@/data/payload/contracts/ProgressiveCommissionConfigurationRequest";
import { parseInputFloatToFloat } from "@/app/infrastructures/misc/Utils";
import moment from "moment";

export class ProgressiveCommissionListRequest {
  status = "";
  page = 1;
  limit = 10;
  isTotalData = false;
  withCpcDetail = false;

  constructor(fields?: Partial<ProgressiveCommissionListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class SubmitProgressiveCommissionConfigurationRequest
  implements ProgressiveCommissionConfigurationRequest {
  id = 0;
  startDate = "";
  endDate = "";
  bookingShipmentPrefix = "";
  bookingAppliedTo = "";
  quantifier = "";
  termsAndConditions = "";
  tierCalculation: Array<any> = [];

  constructor(
    fields?: Partial<SubmitProgressiveCommissionConfigurationRequest>
  ) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      cpc_name: `Progresif Komisi ${moment(this.startDate, "YYYY-MM-DD").format(
        "DD MMMM YYYY"
      )} - ${moment(this.endDate, "YYYY-MM-DD").format("DD MMMM YYYY")}`,
      cpc_applied_to: "pos",
      cpc_start_date: this.startDate,
      cpc_end_date: this.endDate,
      cpc_booking_source: this.bookingAppliedTo,
      cpc_shipment_prefix: this.bookingShipmentPrefix,
      cpc_reference: this.quantifier,
      cpc_term_condition: this.termsAndConditions,
      cpc_detail: this.tierCalculation.map((item: any, index: number) => {
        return {
          cpcd_milestone_id: index + 1,
          cpcd_milestone_limit: parseInputFloatToFloat(item.milestone),
          cpcd_commission_additional: parseInputFloatToFloat(item.commission)
        };
      })
    });
  }
}
