
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean
    }
  },
  components: {}
})
export default class OcbcPopupModal extends Vue {
  props: any = this.$props;
  closeDialog() {
    this.$emit("update:modelValue", false);
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
  }
  registerNow() {
    window.open("https://bit.ly/kta-lionparcel", "_blank", "", true);
  }
}
