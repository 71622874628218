import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 overflow-hidden" }
const _hoisted_2 = { class: "fixed flex items-center top-0 right-0 p-8 text-gray-lp-800 text-12px font-normal italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_section_filter_dashboard = _resolveComponent("section-filter-dashboard")!
  const _component_section_delivery_dashboard = _resolveComponent("section-delivery-dashboard")!
  const _component_section_delivery_performance_dashboard = _resolveComponent("section-delivery-performance-dashboard")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Refresh, { class: "text-red-lp-300 mr-2" }),
      _createTextVNode(" Sinkronisasi Data Terakhir " + _toDisplayString(_ctx.formattedLastSyncDataDashboard), 1)
    ]),
    _createVNode(_component_section_filter_dashboard, { onOnFilter: _ctx.reRenderDashboard }, null, 8, ["onOnFilter"]),
    (_openBlock(), _createBlock("div", {
      class: "overflow-y-auto space-y-6",
      style: {"height":"calc(100vh - 165px)"},
      key: _ctx.performanceDashboardKey
    }, [
      (_ctx.checkActiveSectionDashboard('delivery'))
        ? (_openBlock(), _createBlock(_component_section_delivery_dashboard, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.checkActiveSectionDashboard('performance'))
        ? (_openBlock(), _createBlock(_component_section_delivery_performance_dashboard, { key: 1 }))
        : _createCommentVNode("", true)
    ]))
  ]))
}