import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-screen flex flex-col" }
const _hoisted_2 = {
  class: "overflow-auto flex-grow",
  style: {"height":"calc(100vh - 170px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainDashboardV2 = _resolveComponent("MainDashboardV2")!
  const _component_PerformanceDashboard = _resolveComponent("PerformanceDashboard")!
  const _component_CodDashboard = _resolveComponent("CodDashboard")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      modelValue: _ctx.activeMenu,
      onChange: _ctx.onChangeTabs,
      data: _ctx.tabs,
      rounded: false,
      marginBottom: 0,
      titleSize: 16,
      gap: 10
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_ctx.activeMenu === 'main-dashboard')
            ? (_openBlock(), _createBlock(_component_MainDashboardV2, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.activeMenu === 'performance-dashboard')
            ? (_openBlock(), _createBlock(_component_PerformanceDashboard, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.activeMenu === 'cod-dashboard')
            ? (_openBlock(), _createBlock(_component_CodDashboard, { key: 2 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onChange", "data"])
  ]))
}