import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerDashboard = _resolveComponent("BannerDashboard")!
  const _component_ShortcutBooking = _resolveComponent("ShortcutBooking")!
  const _component_OcbcPopupModal = _resolveComponent("OcbcPopupModal")!
  const _component_BNIModal = _resolveComponent("BNIModal")!
  const _component_ReferralModal = _resolveComponent("ReferralModal")!
  const _component_ReferralBelanja = _resolveComponent("ReferralBelanja")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (!_ctx.dataProfile.isPosChildAccount)
      ? (_openBlock(), _createBlock(_component_BannerDashboard, {
          key: _ctx.bannerData,
          data: _ctx.bannerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (_ctx.isShortcutEnable)
      ? (_openBlock(), _createBlock(_component_ShortcutBooking, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_OcbcPopupModal, {
      modelValue: _ctx.ocbcDialogModelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.ocbcDialogModelValue = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_BNIModal, {
      modelValue: _ctx.BNIDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.BNIDialog = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_ReferralModal, {
      modelValue: _ctx.referralDialogModelValue,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.referralDialogModelValue = $event)),
      "referral-code": _ctx.referralCode
    }, null, 8, ["modelValue", "referral-code"]),
    _createVNode(_component_ReferralBelanja, {
      link: _ctx.affiliateLink,
      expiredAt: _ctx.expiredAt,
      qrCode: _ctx.qrCode,
      modelValue: _ctx.referralBelanja,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.referralBelanja = $event))
    }, null, 8, ["link", "expiredAt", "qrCode", "modelValue"])
  ]))
}