
import { Options, Vue } from "vue-class-component";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import MainDashboardV2 from "@/app/ui/views/dashboard/component/pos/main-dashboard-v2.vue";
import CodDashboard from "@/app/ui/views/dashboard/cod-dashboard/index.vue";
import PerformanceDashboard from "@/app/ui/views/dashboard/component/pos/performance-dashboard.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import { FlagsDashboardCod } from "@/feature-flags/dashboard/cod";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

@Options({
  components: { CodDashboard, MainDashboardV2, PerformanceDashboard }
})
export default class DashboardPos extends Vue {
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail;
  }
  get activeMenu() {
    return DashboardController.activeMenuTabs.main;
  }
  onChangeTabs(value: string) {
    DashboardController.setActiveMenuTabs({ main: value });

    const tab: any = {
      "main-dashboard": "pos_dashboard_utama",
      "performance-dashboard": "pos_dashboard_performa",
      "cod-dashboard": "dashboard_cod_clicked",
    }
    GTMCommonEvent(tab[value]);
  }
  created() {
    DashboardController.setActiveMenuTabs({
      main: "main-dashboard"
    });
  }
  get tabs() {
    const tempTabs = [
      {
        value: "main-dashboard",
        title: "Utama"
      }
    ];
    if (
      FlagsMedusa.config_pos_performance_dashboard.getValue() &&
      !this.dataProfile.isPosChildAccount
    ) {
      tempTabs.push({
        value: "performance-dashboard",
        title: "Performa"
      });
    }
    if (
      this.accountTypeDetail.isCodDelivery &&
      !this.dataProfile.isPosChildAccount &&
      FlagsDashboardCod.permission_show_tab_cod_dashboard.isEnabled()
    ) {
      tempTabs.push({
        value: "cod-dashboard",
        title: "COD"
      });
    }
    return tempTabs;
  }
}
