/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { container } from "tsyringe";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ProgressiveCommissionList,
  ProgressiveCommissionDetail
} from "@/domain/entities/ProgressiveCommissionConfiguration";
import {
  ProgressiveCommissionListRequest,
  SubmitProgressiveCommissionConfigurationRequest
} from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { ProgressiveCommissionConfigurationPresenter } from "../presenters/ProgressiveCommissionConfigurationPresenter";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface ProgressiveCommissionConfigurationState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  progressiveCommissionList: ProgressiveCommissionList;
  progressiveCommissionDetail: ProgressiveCommissionDetail;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "progressive-commission-configuration"
})
class ProgressiveCommissionConfigurationStore extends VuexModule
  implements ProgressiveCommissionConfigurationState {
  isLoading = false;
  isError = false;
  errorCause = "";
  progressiveCommissionList = new ProgressiveCommissionList(
    new Pagination(1, 10),
    []
  );
  progressiveCommissionDetail = new ProgressiveCommissionDetail();

  @Action
  public async getProgressiveCommissionList(
    params: ProgressiveCommissionListRequest
  ) {
    this.setLoading(true);
    const presenter = container.resolve(
      ProgressiveCommissionConfigurationPresenter
    );
    return presenter
      .getProgressiveCommissionList(params)
      .then((res: ProgressiveCommissionList) => {
        this.setProgressiveCommissionList(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  getProgressiveCommissionDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(
      ProgressiveCommissionConfigurationPresenter
    );
    return presenter
      .getProgressiveCommissionDetail(id)
      .then((res: ProgressiveCommissionDetail) => {
        this.setProgressiveCommissionDetail(res);
        this.setErrorCause("");
        this.setError(false);
        return true;
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setError(error: boolean) {
    this.isError = error;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }

  @Mutation
  public setProgressiveCommissionList(data: ProgressiveCommissionList) {
    this.progressiveCommissionList = data;
  }

  @Mutation
  public setProgressiveCommissionDetail(res: ProgressiveCommissionDetail) {
    this.progressiveCommissionDetail = res;
  }

  @Action
  public async addProgressiveCommissionConfiguration(
    payload: SubmitProgressiveCommissionConfigurationRequest
  ) {
    const presenter = container.resolve(
      ProgressiveCommissionConfigurationPresenter
    );
    return presenter
      .addProgressiveCommissionConfiguration(payload)
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Program Gagal!")
        );
        return false;
      });
  }
  @Action
  public async editProgressiveCommissionConfiguration(
    payload: SubmitProgressiveCommissionConfigurationRequest
  ) {
    const presenter = container.resolve(
      ProgressiveCommissionConfigurationPresenter
    );
    return presenter
      .editProgressiveCommissionConfiguration(payload)
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Program Gagal!")
        );
        return false;
      });
  }
}

export const ProgressiveCommissionConfigurationController = getModule(
  ProgressiveCommissionConfigurationStore
);
